import React from 'react'
import { TERMS_LIST } from '../terms.constants'
import TermsLayout from './terms-list.styles'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

const TermsList = () => {
  return (
    <TermsLayout>
      <TermsLayout.container>
        {TERMS_LIST.map((term) => (
          <TermsLayout.listItem key={term.id}>
            <TermsLayout.title
              data-element-location={DataElementLocations.BODY}
              data-element-label='terms.link.list'
              data-element-id={`sitelio-terms-link-list-${term.id}`}
              target={term.id === 'privacy-notice' ? '_blank' : '_self'}
              to={
                term.id === 'privacy-notice'
                  ? ' https://newfold.com/privacy-center '
                  : `/terms/${term.id}`
              }
            >
              {term.title}
            </TermsLayout.title>
            <TermsLayout.subtitle>{term.subtitle}</TermsLayout.subtitle>
          </TermsLayout.listItem>
        ))}
      </TermsLayout.container>
    </TermsLayout>
  )
}

export default TermsList
