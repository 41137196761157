import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import TermsList from '../../components/terms/terms-list/terms-list'

const Terms = () => {
  return (
    <Layout>
      <SEO title='Terms' pageType='home' flowType='none' />
      <TermsList />
    </Layout>
  )
}

export default Terms
