import styled from 'styled-components'
import theme, { media } from '../../../helpers/theme'
import Link from 'gatsby-link'

const TermsLayout = styled.div`
  padding: ${theme.space[8]}px 0;
  font-family: 'Open Sans';
`

TermsLayout.container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 0 auto;

  ${media(768)} {
    width: 750px;
  }

  ${media(992)} {
    width: 970px;
  }

  ${media(1200)} {
    width: 1170px;
  }
`

TermsLayout.listItem = styled.div`
  padding: ${theme.space[5]}px;
`

TermsLayout.title = styled(Link)`
  font-size: ${theme.font['xl']};
  color: ${theme.colors.blue};
  text-decoration: none;
  transition: 0.7s;

  :hover {
    color: ${theme.colors.font.tealhighlight};
    cursor: pointer;
  }
`
TermsLayout.subtitle = styled.div`
  padding-top: ${theme.space[2]}px;
  color: ${theme.colors.font.fadedgray};
`

export default TermsLayout
